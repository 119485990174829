import React from "react";
import styled from "styled-components";

const StyledFooter = styled.div`
   //      background:white;
         position:relative;
         bottom:0px;
         width:100%;
  height: fit-content;
  margin: 0;

  text-align: center;
  font-size: 13px;
  border-top: 1px solid #e4e4e4;
  padding-top: 15px;
 
    margin: 0;
    background: transparent;
    padding-bottom: 15px;
    margin-top:25px;
         li{
             float:right;
              margin-right:35px;
             
             @media(max-width:350px){
                 width: 100%;
                 margin-top: 15px;
                 margin-right: 0;
             }
             
         }
  &.editorFooter{
  
    font-size:13px;
  }

  ul{
    list-style-type:none;
  }
   
`;

const Footer: React.FunctionComponent = ({ props: any,typeVariable}) => {

  return (
    <StyledFooter className={typeVariable === "editor" ? "editorFooter" : ""}>
        <ul style={typeVariable === 'editor' ? { marginTop: '27px' } : {}}>
            <li><a href={"https://www.obalero.com/contact"} target={"_blank"}>Contact us</a></li>
            <li><a href={"https://www.obalero.com/terms-of-use/"} target={"_blank"}>Privacy & Terms</a></li>
            <li><a href={"https://www.obalero.com/"} target={"_blank"}>Obalero Website</a></li>
        </ul>
    </StyledFooter>
  )
};
export default Footer;